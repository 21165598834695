import { Alert } from "@mui/material";
import dayjs from "dayjs";
import Image from "next/image";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { forwardRef, memo, useCallback, useEffect, useState } from "react";

const Announcement = forwardRef(function Announcement(data, ref){
    const router = useRouter();
    const [ds, setDs] = useState(null);
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
        closeSnackbar(data.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.id, closeSnackbar]);

    useEffect(()=>{
        const dateSold = dayjs(data.date_updated);
        const currentDate = dayjs();
        let minutes = currentDate.diff(dateSold, 'minutes');
        const hours = Math.floor(minutes / 60);
        minutes = minutes - ( hours * 60);
        setDs({hours: hours,minutes: minutes});
    },[data]);

    return(
        <Alert
            ref={ref}
            icon={
                <div
                    className="relative flex sm:w-[160px] sm:h-[80px] w-[120px] h-[60px] border-r border-red-100 cursor-pointer"
                    onClick={()=>{
                        router.push(`${data.url}`);
                        closeSnackbar(data.id);
                    }}
                >
                    <Image
                        className="object-fill"
                        src={data.banner}
                        alt={data.id}
                        width={800}
                        height={800}
                        priority
                    />
                </div>
            }
            onClose={handleDismiss}
            severity="info"
            className={` !bg-white !text-black !p-0 border border-red-100 rounded-md shadow shadow-red-100`}
            sx={{ fontWeight:800, width: '100%',
                '& .MuiAlert-icon' :{
                    padding: 0,
                },
                '& .MuiAlert-message' :{
                    paddingRight: '26px',
                },
                '& .MuiAlert-action':{
                    position: 'absolute',
                    top: 1,
                    right: 1,
                    margin: 0,
                    padding: 0,
                }
                }}
        >
            <div className="h-full flex flex-col justify-center align-middle">
                <div className="flex flex-col" dangerouslySetInnerHTML={{__html: data.message}}></div>
                {/* <div className="pt-2">
                    <span className="text-gray-400">{`${ds?.hours == 0 ? '' : `${ds?.hours} hour${ds?.hours > 1 ? 's ' : ' '}`}${ds?.minutes} minute${ds?.minutes > 1 ? 's' : ''}`} ago</span>
                </div> */}
            </div>
        </Alert>
    );
});

export default memo(Announcement);