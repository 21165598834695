import Head from "next/head";
import Footer from "../Footer";
import Navigation from "../Navigation";
import BottomNav from "../Navigation/Mobile/Bottom";
import { useDispatch, useSelector } from "react-redux";
import { addInfo, removeInfo } from "@/redux/gulongph.slice";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import RecentSoldNotif from "../Notification/RecentSold";
import Announcement from "../Notification/Announcement";
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from "dayjs";
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton } from "@mui/material";


Layout.propTypes = {
    pageTitle: PropTypes.string,
    children: PropTypes.array
};

const fetcher = (url) => fetch(url).then((res) => res.json());

const swrOptions = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
};

export default function Layout({ pageTitle, children }){
    const pTitle = pageTitle ? "Gulong PH - " + pageTitle : "Gulong PH";
    const dispatch = useDispatch();
    const router = useRouter();
    const recentSoldProducts = useSelector((state) => state.gulongph.recent_sold);
    const chkAnnouncement = useSelector((state) => state.gulongph.announcement);
    const isSurveyDone = useSelector((state) => state.gulongph.survey);
    const sSurvey = useSelector((state) => state.gulongph.sSurvey);
    const userStart = useSelector((state) => state.gulongph.s_time);
    const { data } = useSWR(`${process.env.NEXT_PUBLIC_API_URL}/0b0a0250751ea77b323c2cf3f66e714cb4f6181b`, fetcher);
    const { data: questions } = useSWR(`${process.env.NEXT_PUBLIC_API_URL}/squestions`, fetcher, swrOptions);
    const [surveyDialogLoading, setSurveyDialogLoading] = useState(false);
    const [surveyDialog, setSurveyDialog] = useState(false);
    const [surveyDialogMsg, setSurveyDialogMsg] = useState(null);
    const [surveyStatusMsg, setSurveyStatusMsg] = useState(null);

    const handleCloseSurveyDialog = async (isForm = false) =>{
        if(!isForm){
            await fetch(`${process.env.NEXT_PUBLIC_API_URL}/saquestions`,{
                method: "POST",
                headers:{
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ a: 999 }), // 999 if user close the survey
            });
        }
        setSurveyDialog(false);
        dispatch(addInfo({survey: 1}));
    };

    const submitSurvey = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const cbValues = Object.values(formJson);
        if(cbValues.length <= 0){
            setSurveyDialogMsg('Please select at least one.');
            return;
        }
        setSurveyDialogLoading(true);
        const postSurvey = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/saquestions`,{
            method: "POST",
            headers:{
                'Content-type': 'application/json',
            },
            body: JSON.stringify({ a: cbValues.join() }),
        });
        let postSurveyResult = await postSurvey.json();
        if(postSurveyResult?.status == 200){
            setSurveyStatusMsg(postSurveyResult.message);
            setSurveyDialogMsg(null);
            setTimeout(()=>{
                handleCloseSurveyDialog(1);
            }, 3000);
        }
    };

    useEffect(() => { // for cache checking if date is set today
        if(router.asPath.includes('/shop') || router.asPath.includes('/product')){
            if (typeof userStart === 'undefined'){
                const currentDateTime = Date.now();
                dispatch(addInfo({s_time: currentDateTime}));
            }
            if(dayjs(userStart).format('DDMMYYYY') != dayjs().format('DDMMYYYY')){
                const currentDateTime = Date.now();
                dispatch(addInfo({s_time: currentDateTime}));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStart]);

    useEffect(() => {
        if(isSurveyDone == 1) return; // check cache if user already do or open the survey
        let timeStartS = sSurvey;
        if (typeof sSurvey === 'undefined'){
            timeStartS = dayjs().subtract(1, 'day'); // add temporary date for 1st time user
        }
        if(dayjs(timeStartS).format('DDMMYYYY') == dayjs().format('DDMMYYYY')) return;  // if true user already open the survey or already limit for survey
        if(router.asPath.includes('/shop') || router.asPath.includes('/product')){
            const showSurveyAt = dayjs(userStart).add(5, 'minute'); // 5 minutes to show the survey on the time user land in shop or product page
            const currentDateTime = Date.now();
            const intervalId = setInterval(() => {
                try {
                    if(dayjs().valueOf() > dayjs(showSurveyAt).valueOf()){
                        fetch(`${process.env.NEXT_PUBLIC_API_URL}/st`)
                        .then((res) => res.json())
                        .then((data) => {
                            if(parseFloat(data?.st) < 5 && data?.s == 0){ //limit survey
                                setSurveyDialog(true);
                                clearInterval(intervalId);
                                dispatch(addInfo({sSurvey: currentDateTime}));
                            }
                            else if(data?.s == 1){ // already show survey to this user
                                clearInterval(intervalId);
                                dispatch(addInfo({sSurvey: currentDateTime}));
                            }
                        }).catch((error) => {
                            console.log(error);
                            clearInterval(intervalId);
                            dispatch(addInfo({sSurvey: currentDateTime}));
                        });
                    }
                } catch (error) {
                    dispatch(addInfo({sSurvey: currentDateTime}));
                    clearInterval(intervalId);
                }
            }, 60000); // every 1 minute
            return () => clearInterval(intervalId); // cleanup
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSurveyDone]);

    useEffect(()=>{
        if(!data) return;
        const checkRsDt = data?.filter(d => d.type == 'recent_sold');
        const checkAnnouncement = data?.filter(d => d.type == 'announcement');
        let finalRs = [];
        if(checkRsDt.length > 0){
            let rs = [];
            let arRs = recentSoldProducts?.map(d => { return d.id });
            let dtRs = checkRsDt?.map(d => { return d.id });
            rs = recentSoldProducts ? dtRs.filter(val => !arRs.includes(val)) : checkRsDt;
            if(rs.length > 0) {
                let dtrs = [];
                if(recentSoldProducts){
                    let tempRs = rs;
                    dtrs = tempRs.map((d, x)=>{
                        let checkRs = checkRsDt.filter(v => v.id == d);
                        if(checkRs) return checkRs[0];
                    });
                }
                dispatch(addInfo({recent_sold: data}));
                finalRs = dtrs.length > 0 ? dtrs : rs;
                [...finalRs].sort(function(a,b){
                    return new Date(b.date_updated) - new Date(a.date_updated);
                });
                finalRs.forEach((d, x) => {
                    setTimeout(() => {
                        enqueueSnackbar(d.name, {
                            variant: "soldNotif",
                            autoHideDuration: 5000,
                            make: d.make,
                            date_updated: d.date_updated,
                            slug: d.slug,
                            image: d.image,
                        });
                    }, 16000 * x);
                });
            }
            if(checkAnnouncement.length > 0){
                let tm = finalRs.length > 0 ? (parseInt(finalRs.length) * 12000) + 4000 : 0;
                setTimeout(() => {
                    let annce = [];
                    let arAnnce = chkAnnouncement?.map(d => { return d.id });
                    let dtAnnce = checkAnnouncement?.map(d => { return d.id });
                    annce = chkAnnouncement ? dtAnnce.filter(val => !arAnnce.includes(val)) : checkAnnouncement;
                    if(annce.length > 0) {
                        let dtannce = [];
                        if(chkAnnouncement){
                            let tempRs = annce;
                            dtannce = tempRs.map((d, x)=>{
                                let checkRs = checkAnnouncement.filter(v => v.id == d);
                                if(checkRs) return checkRs[0];
                            });
                        }
                        dispatch(addInfo({announcement: checkAnnouncement}));
                        const finalAnnce = dtannce.length > 0 ? dtannce : annce;
                        [...finalAnnce].sort(function(a,b){
                            return new Date(b.date_updated) - new Date(a.date_updated);
                        });
                        checkAnnouncement.forEach((d, x) => {
                            setTimeout(() => {
                                enqueueSnackbar(d.message, {
                                    variant: "announcement",
                                    autoHideDuration: 5000,
                                    url: d.url,
                                    date_updated: d.date_updated,
                                    banner: d.banner,
                                });
                            }, 15000 * x);
                        });
                    }
                }, tm);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data]);

    useEffect(()=>{
        if(!router.isReady) return;
        if(router.query.utm_source){
            dispatch(addInfo({utm_source: router.query.utm_source}));
        }
        else{
            dispatch(removeInfo({utm_source: 'remove'}));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router]);
    return(
        <SnackbarProvider
            Components={{
                soldNotif: RecentSoldNotif,
                announcement: Announcement,
            }}
            classes={{
                containerRoot: 'sm:!bottom-[14px] sm:!left-[20px] !bottom-auto !left-auto sm:!top-auto sm:!right-auto !-top-1.5 !right-0 !max-w-full'
            }}
            maxSnack={2}
        >
            <div className="lg:pt-[125px] pt-[150px]">
                <Head>
                    <title>{`${pTitle}`}</title>
                    <link rel="shortcut icon" href="/assets/images/gulong-icon-32x32.png" />
                    <link rel="icon" href="/assets/images/gulong-icon-32x32.png" />
                    <link rel="icon" href="/assets/images/gulong-icon-192x192.png" sizes="192x192" />
                    <link rel="apple-touch-icon-precomposed" href="/assets/images/gulong-icon-180x180.png" />
                    <meta name="title" content="Gulong.ph - The Number 1 Online Tire Shop in the Philippines"/>
                    <meta name="description" content="The Number 1 Online Tire Shop in the Philippines - FREE Installation and FREE Delivery on quality tires from trusted brands. Free installation near you. Shop now at Gulong.ph to get more savings in a click and fit away!"/>

                    <meta property="og:type" content="website"/>
                    <meta property="og:url" content={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}`} />
                    <meta property="og:title" content="Gulong.ph - The Number 1 Online Tire Shop in the Philippines"/>
                    <meta property="og:description" content="The Number 1 Online Tire Shop in the Philippines - FREE Installation and FREE Delivery on quality tires from trusted brands. Free installation near you. Shop now at Gulong.ph to get more savings in a click and fit away!"/>
                    <meta property="og:image" content={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/assets/images/seo/seo_banner_icon_landscape.jpg`} />

                    <meta property="twitter:card" content="summary_large_image"/>
                    <meta property="twitter:url" content={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}`} />
                    <meta property="twitter:title" content="Gulong.ph - The Number 1 Online Tire Shop in the Philippines"/>
                    <meta property="twitter:description" content="The Number 1 Online Tire Shop in the Philippines - FREE Installation and FREE Delivery on quality tires from trusted brands. Free installation near you. Shop now at Gulong.ph to get more savings in a click and fit away!"/>
                    <meta property="twitter:image" content={`${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/assets/images/seo/seo_banner_icon_landscape.jpg`} />
                </Head>
                <Navigation/>
                <main className="">
                    <Dialog
                        open={surveyDialog}
                        onClose={()=>handleCloseSurveyDialog()}
                        aria-labelledby="survey-dialog-title"
                        aria-describedby="survey-dialog-description"
                        sx={{
                            '& .MuiPaper-root': {
                                margin: {xs: '5px', md: '24px'}
                            }
                        }}
                        PaperProps={{
                            component: 'form',
                            onSubmit: (event) => {
                                submitSurvey(event);
                            },
                        }}
                    >
                        <DialogTitle className="text-center max-w-sm !mx-auto flex flex-col sm:!px-8 !px-8 !pb-2" id="survey-dialog-title">
                            <span className="text-gray-900 tracking-normal leading-5 text-sm font-bold">Hi Ka-Gulong! We&apos;ve notice that you&apos;ve been browsing this page for a while</span>
                            <span className="text-gray-900 tracking-normal leading-5 text-sm font-bold">Would you mind sharing your reason(s) so we can work improving the experience for our customers?</span>
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={()=>handleCloseSurveyDialog()}
                            sx={(theme) => ({
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: theme.palette.grey[500],
                            })}
                        >
                            <CloseIcon />
                        </IconButton>
                        <DialogContent className="!pt-2 !pb-0 border-t border-red-200">
                            <DialogContentText id="survey-dialog-description">
                                <FormControl error={surveyDialogMsg} sx={{ m: 0, width: '100%' }} className="space-y-1" component="fieldset" variant="standard">
                                    <FormLabel component="legend" className="!font-bold !text-gray-900 text-sm mb-1">Select all that apply:</FormLabel>
                                    {questions?.map((q, x) => {
                                        return(
                                            <FormGroup key={q.id+'question'} className="border border-red-300 rounded px-2 w-full">
                                                <FormControlLabel
                                                    className="!sm:text-normal !text-sm"
                                                    sx={{
                                                        color: 'rgb(17, 24, 39, 1)',
                                                        '& .MuiFormControlLabel-label': {
                                                            fontWeight: 'bold',
                                                            fontSize: { xs: '0.75rem', md: '1rem'}
                                                        }
                                                    }}
                                                    control={
                                                        <Checkbox sx={{
                                                            borderRadius: '5px',
                                                            color: 'rgb(252, 165, 165, 1)',
                                                            '&.Mui-checked': {
                                                                color: 'rgb(252, 165, 165, 1)',
                                                            },
                                                        }}
                                                        name={`questions-${q.id}`}
                                                        value={q.id}
                                                        />
                                                    }
                                                    label={q.question}
                                                />
                                            </FormGroup>
                                        );
                                    })}
                                    <FormHelperText>{surveyDialogMsg}</FormHelperText>
                                    {surveyStatusMsg && (
                                        <h1 className="text-green-600 font-bold text-lg text-center">{surveyStatusMsg}</h1>
                                    )}
                                </FormControl>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ justifyContent: 'center', flexDirection: 'column', padding: '10px 24px'}}>
                            <button type="submit" disabled={surveyDialogLoading} className="disabled:text-gray-400 text-white rounded bg-red-600 hover:text-green-300 font-bold text-lg w-full py-1">SUBMIT</button>
                            <button type="button" disabled={surveyDialogLoading} onClick={()=>handleCloseSurveyDialog()} className="disabled:text-gray-400 rounded text-red-600 hover:text-gray-700 underline font-bold text-lg w-full !mx-0 mt-2 tracking-wide">&nbsp;Exit this Survey&nbsp;</button>
                        </DialogActions>
                    </Dialog>
                    {children}
                </main>
                <Footer/>
                <BottomNav/>
            </div>
        </SnackbarProvider>
    )
}
