import { alpha, styled  } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const RedditTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
    ))(({ theme }) => ({
        '& .MuiOutlinedInput-root':{
            border:"1px solid #fee2e2",
        },
        '& .MuiFilledInput-root': {
            border: '1px solid #fee2e2',
            overflow: 'hidden',
            borderRadius: 4,
            backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
            transition: theme.transitions.create([
                'border-color',
                'background-color',
                'box-shadow',
            ]),
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent',
                boxShadow: `${alpha(theme.palette.secondary.main, 0.25)} 0 0 0 2px`,
                borderColor: theme.palette.secondary.main,
            },
            '&.Mui-disabled':{
                color: '#000000',
                WebkitTextFillColor: '#000000',
                backgroundColor: '#ffffff',
            },
            '&.Mui-disabled input':{
                color: '#000000',
                WebkitTextFillColor: '#000000',
                backgroundColor: '#ffffff',
            }
        },
        '& .MuiFormLabel-root': {
            '&.Mui-focused': {
                color: theme.palette.label.sub,
            },
        },
        "& fieldset": { border: 'none' },
}));


export default function Input({ ...props }){
    return (
        <RedditTextField
            {...props}
        />
    );
}
