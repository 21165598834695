'use client';

import Input from "@/components/Elements/Input";
import { Alert, Chip, IconButton, InputAdornment, Snackbar, Stack } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Fragment, useCallback, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { addInfo, removeInfo } from "@/redux/gulongph.slice";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Item from "../../Shop/Items/Item";
import { AnimatePresence, motion } from "framer-motion";
import CloseIcon from '@mui/icons-material/Close';

const defaultPlaceholder = "Search for Tire Size, Tire Brand or Car Model";
const placeholders = ['185/65/R15', 'Michelin Energy XM2+', 'Toyota Vios'];

export default function SearchBox({...props}){
    const recentSeach = useSelector((state) => state.gulongph.recent_search);
    const dispatch = useDispatch();
    const router = useRouter();
    const [suggestions, setSuggestions] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [openPop, setOpenPop] = useState(false);
    const [openSearchBox, setOpenSearchBox] = useState(false);
    const [promoBrands, setPromoBrands] = useState(null);
    const [topBrands, setTopBrands] = useState(null);
    const [tempId, setTempId] = useState('');

    const handleClickPop = () => {
        setOpenPop(true);
    };
    const handleClosePop = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenPop(false);
    };

    const handleOpenSearchBox = () => {
        setOpenSearchBox(true);
        setTimeout(()=>{
            setTempId('search-box-container');
        }, 1000);
    };
    const handleCloseSearchBox = () => {
        setOpenSearchBox(false);
        setTempId('');
        if(suggestions?.length <= 0) setInputValue('');
    };

    const handleClickBrandChip = (b) => {
        handleCloseSearchBox();
        dispatch(addInfo({recent_search: b}));
        delete router.query.sn;
        delete router.query.car_make;
        delete router.query.car_year;
        delete router.query.car_model;
        delete router.query.section_width;
        delete router.query.aspect_ratio;
        delete router.query.rim_size;
        delete router.query.search;
        router.push({
            pathname: "/shop",
            query: { "b": b },
        },
          undefined, { shallow: true}
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'search_now',
        });
    };

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const searchProducts = async (q) => {
        if(q.length > 1){
            setLoadingSearch(true);
            let tmOut = suggestions.length >= 5 ? 1555 : 50;
            setSuggestions([]);
            setTimeout( async()=>{
                const sP = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/61a03260c06ca2e497f2d164b7b6590819a019e6`,{
                    method: "POST",
                    headers:{
                        'Content-type': 'application/json',
                    },
                    body: JSON.stringify({ q: q }),
                });
                let sProducts = await sP.json();
                if(sProducts.products?.length > 0){
                    setSuggestions(sProducts.products);
                    if(sProducts?.pr){
                        const buy3Plus1 = sProducts?.pr?.find((promo, index) => {
                            if(promo.name == "3+1"){
                                return promo;
                            }
                        });
                        if(buy3Plus1?.brands){
                            const promoB = buy3Plus1?.brands.split(",");
                            setPromoBrands(promoB);
                        }
                    }
                }
                else{
                    setSuggestions([]);
                }
                setLoadingSearch(false);
            }, tmOut);
        }
        else{
            setSuggestions([]);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const optimizedFn = useCallback(debounce(searchProducts),[]);

    const enterSearch = (e) => {
        if(e.keyCode == 13){
            search();
        }
        if(e.target.value.trim().length == 0){
            setSuggestions([]);
        }
    };
    const search = (val = null) => {
        let sv = val != null ? val : inputValue;
        if(sv.length <= 1) return;
        handleCloseSearchBox();
        dispatch(addInfo({recent_search: sv}));
        delete router.query.sn;
        delete router.query.car_make;
        delete router.query.car_year;
        delete router.query.car_model;
        delete router.query.section_width;
        delete router.query.aspect_ratio;
        delete router.query.rim_size;
        delete router.query.search;
        router.push({
            pathname: "/shop",
            query: { "search": sv },
        },
          undefined, { shallow: true}
        );
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'search_now',
        });
    };

    useEffect(()=>{
        if(!router.isReady) return;
        if(router.query.search){
            setInputValue(router.query.search);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router.isReady]);

    useEffect(()=>{
        async function fetchData() {
            const sP = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/61a03260c06ca2e497f2d164b7b6590819a019e6`,{
                method: "POST",
                headers:{
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({ q: 'tb', init: 'tb' }),
            });
            let res = await sP.json();
            if(res?.tb){
                const tb = res.tb.map((b)=> {return b.make;});
                setTopBrands(tb);
            }
        }
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const [index, setIndex] = useState(0);
    const [pauseTyping, setPauseTyping] = useState(0);
    const [placeholder, setPlaceholder] = useState(defaultPlaceholder);

    useEffect(() => {
        let place = '';
        const typingInterval = setInterval(() => {
            if (place.length < placeholders[index].length) {
                place += placeholders[index][place.length];
                setPlaceholder(place);
            }
            else {
                setTimeout(function(){
                    clearInterval(typingInterval);
                    const deletingInterval = setInterval(() => {
                        if (place.length > 0) {
                            place = place.slice(0, place.length - 1);
                            setPlaceholder(place);
                        }
                        else {
                            clearInterval(deletingInterval);
                            setIndex((index + 1) % placeholders.length);
                        }
                    }, 150);
                }, 1000)
            }
        }, 200);
        return () => clearInterval(typingInterval);
    }, [index, pauseTyping]);

    return(
        <div {...props}>
            <Snackbar
                open={openPop}
                autoHideDuration={6000}
                onClose={handleClosePop}
                className="!top-3 !right-24 !bottom-auto !left-auto"
            >
                <Alert
                    icon={
                        <svg className="text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.29 5.89l-10 10a.996.996 0 0 1-1.41 0l-2.83-2.83a.996.996 0 1 1 1.41-1.41l2.12 2.12l9.29-9.29a.996.996 0 0 1 1.41 0c.4.39.4 1.02.01 1.41zM12 20c-4.71 0-8.48-4.09-7.95-8.9c.39-3.52 3.12-6.41 6.61-6.99c1.81-.3 3.53.02 4.99.78a1.003 1.003 0 0 0 .93-1.78c-1.47-.75-3.13-1.16-4.9-1.11c-5.14.16-9.41 4.34-9.67 9.47C1.72 17.24 6.3 22 12 22c1.2 0 2.34-.21 3.41-.6c.68-.25.87-1.13.35-1.65a.98.98 0 0 0-1.04-.23c-.85.31-1.77.48-2.72.48zm7-5h-2c-.55 0-1 .45-1 1s.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1h-2v-2c0-.55-.45-1-1-1s-1 .45-1 1v2z"/></svg>
                    }
                    onClose={handleClosePop}
                    severity="success"
                    className="!bg-green-600 !text-white"
                    sx={{ fontWeight:800, width: '100%' }}
                >
                    Added to Cart!
                </Alert>
            </Snackbar>
            <div className={`${openSearchBox ? 'hidden' : ''} w-full relative`}>
                <button
                    type="button"
                    className="w-full h-[42px] px-2 rounded-md border border-red-300 relative cursor-pointer flex flex-row items-center align-middle"
                    onClick={()=>handleOpenSearchBox()}
                    onTouchEnd={()=>handleOpenSearchBox()}
                >
                    <span className={`${router.query?.search?.length > 0 ? 'text-gray-800' : 'text-gray-400'}  items-center`}>{router.query?.search?.length > 0 ? inputValue : placeholder}</span>
                    <SearchIcon
                        onClick={()=> handleOpenSearchBox()}
                        className="absolute top-0.5 right-1 text-red-600 cursor-pointer rounded-full hover:bg-red-100 p-1"
                        aria-label="search tire"
                        fontSize="large"
                    />
                </button>
            </div>
            <AnimatePresence initial={false}>
                {openSearchBox && (
                    <motion.div
                        onClick={(e)=>{
                            e.stopPropagation();
                            e.preventDefault();
                            if(e.target.id == "search-box-container"){
                                handleCloseSearchBox();
                            }
                        }}
                        initial={{
                            y: -10,
                            opacity: 0,
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            zIndex: '9999',
                            backgroundColor:'rgba(0, 0, 0, 0.8)',
                        }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                        id={tempId}
                    >
                        <motion.div className="relative md:w-8/12 sm:w-10/12 w-full h-auto sm:mx-auto mx-0 sm:px-0 px-1 md:top-2 sm:top-4">
                            <button
                                type="button"
                                onClick={()=>{handleCloseSearchBox();}}
                                className="w-full relative flex align-middle items-center justify-center text-gray-200 text-sm text-center cursor-pointer hover:bg-gray-700/70 rounded-t-md"
                            >
                                Close search box
                                <div className="absolute top-0 right-0  flex align-middle items-center justify-center ">
                                    <CloseIcon fontSize="small"/>
                                </div>
                            </button>
                            <motion.div className="relative bg-white rounded-md mt-1">
                                <Input
                                    autoFocus
                                    className="z-10"
                                    fullWidth
                                    id="searchBox"
                                    size="small"
                                    name="search"
                                    type="text"
                                    label=""
                                    variant="outlined"
                                    placeholder={pauseTyping == 1 ? defaultPlaceholder: placeholder}
                                    onKeyUp={enterSearch}
                                    onFocus={(e) => {
                                        setPauseTyping(1);
                                    }}
                                    onBlur={() => {
                                        setPauseTyping(0);
                                    }}
                                    value={inputValue}
                                    onChange={(e) => {
                                        setInputValue(e.target.value);
                                        optimizedFn(e.target.value);
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={()=> search()}
                                                                className="bg-red-600 text-white hover:text-red-600 group !rounded p-1 -mr-2"
                                                                aria-label="search tire"
                                                                edge="end"
                                                            >
                                                                <SearchIcon className=""/>
                                                            </IconButton>
                                                        </InputAdornment>,
                                    }}
                                />
                                <motion.div
                                    className="relative px-2 py-1"
                                    initial={{ y: -10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: 10, opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <h1 className="text-xs text-gray-400">Top Brands</h1>
                                    <Stack className="mt-1" direction="row" spacing={1}>
                                        {topBrands?.map((b, x)=>{
                                            return(
                                                <Chip
                                                    key={x+b}
                                                    label={b}
                                                    className="!text-red-600 !border-red-200 !h-auto"
                                                    variant="outlined"
                                                    size="small"
                                                    color="error"
                                                    onClick={()=>handleClickBrandChip(b)}
                                                    sx={{
                                                        '& .MuiChip-label':{
                                                            paddingRight: '4px',
                                                            paddingLeft: '4px',
                                                            fontSize:'12px',
                                                            fontWeight: 'bold',
                                                        }
                                                    }}
                                                />
                                            );
                                        })}
                                    </Stack>
                                </motion.div>
                                <motion.div
                                    className={`space-y-0.5 border-b-2 border-gray-200 shadow-lg relative top-0 left-0 w-full overflow-x-hidden overflow-y-scroll bg-white rounded-b-md ${suggestions.length > 0 ? ' min-h-0 max-h-[calc(100vh-170px)]' : ''}`}
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <AnimatePresence>
                                        {(suggestions?.length == 0) && (
                                            <motion.div
                                                className="px-3 py-1 italic text-gray-400 flex flex-col justify-center items-center"
                                                initial={{ display: 'none' }}
                                                animate={{ display: 'flex', opacity: 1 }}
                                            >
                                                {loadingSearch && (
                                                    <p>Searching tires...</p>
                                                )}
                                                {(inputValue.length > 0 && !loadingSearch) ? (
                                                    <p>No suggetions or results. Try clicking search.</p>
                                                ) : (
                                                    <Fragment>
                                                        {(recentSeach?.length > 0 && !loadingSearch) ? (
                                                            <div className="w-full">
                                                                <ul>
                                                                    {recentSeach.map((rs, index) => {
                                                                        return(
                                                                            <li key={index+rs} className="flex flex-row items-center align-middle">
                                                                                <button
                                                                                    type="button"
                                                                                    className="hover:text-blue-600 cursor-pointer flex flex-row items-center align-middle"
                                                                                    onClick={()=>{
                                                                                        setInputValue(rs);
                                                                                        optimizedFn(rs);
                                                                                    }}
                                                                                >
                                                                                    <AccessTimeIcon className="text-gray-400 mr-2 text-sm" fontSize="small" />
                                                                                    <span>{rs}</span>
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={()=>{
                                                                                        dispatch(removeInfo({recent_search: rs}));
                                                                                    }}
                                                                                    className="text-sm text-red-600 hover:text-blue-600 mr-1 ml-auto"
                                                                                >
                                                                                    Remove
                                                                                </button>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <p>{defaultPlaceholder}</p>
                                                                <p>Example: {placeholder}</p>
                                                            </div>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </motion.div>
                                        )}
                                        {suggestions?.map((suggestion, index) => {
                                            return (
                                                <motion.div
                                                    key={suggestion.id}
                                                    className="hover:bg-red-100 cursor-pointer font-semibold"
                                                    initial={{ scale: 0.6, opacity: 0 }}
                                                    animate={{ scale: 1, opacity: 1 }}
                                                    exit={{ scale: 0.8, opacity: 0 }}
                                                    transition={{
                                                        type: "spring",
                                                        duration: 0.5,
                                                        delay: index * 0.4
                                                    }}
                                                >
                                                    <div className={`s-items flex flex-row relative w-full`}>
                                                        <Item
                                                            product={suggestion}
                                                            promobrands={promoBrands}
                                                            index={index}
                                                            view={'list'}
                                                            searchBox={true}
                                                            handleClickPop={handleClickPop}
                                                            handleCloseSearchBox={handleCloseSearchBox}
                                                        />
                                                    </div>
                                                </motion.div>
                                            );
                                        })}
                                        {suggestions.length > 0 && (
                                            <div className="px-1 pt-1">
                                                <button
                                                    type="button"
                                                    className="font-bold py-1 mb-1 rounded-md w-full border border-red-200 bg-red-600 text-white hover:text-blue-600 hover:bg-gray-200 shadow-md"
                                                    onClick={() => {
                                                        search();
                                                        handleCloseSearchBox();
                                                    }}
                                                >
                                                    View All Results
                                                </button>
                                            </div>
                                        )}
                                    </AnimatePresence>
                                </motion.div>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
