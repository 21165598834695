import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
export default function BottomNav(){
    const router = useRouter();
    const [value, setValue] = useState(0);
    useEffect(()=>{
        if(!router.isReady) return;
        switch (router.route) {

            case "/":
                setValue(0);
                break;
            case "/shop":
                setValue(1);
                break;
            case "/shop/[[...params]]":
                setValue(1);
                break;
            default:
                break;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router.isReady]);
    // if(router.route == "/shop/[[...params]]"){
    //     return;
    // }
    return(
        <Box className={`lg:hidden w-full fixed bottom-0 z-40`}>
            <BottomNavigation
                showLabels
                value={value}
                sx={{
                    '&.MuiBottomNavigation-root':{
                        borderTop: "1px solid #fee2e2",
                    },
                    '&.MuiBottomNavigation-root .MuiBottomNavigationAction-root.Mui-selected':{
                        color: "#dc2626",
                    }
                }}
                onChange={(event, newValue) => {
                    switch (newValue) {
                        case 0:
                            setValue(newValue);
                            router.push("/");
                            break;
                        case 1:
                            setValue(newValue);
                            router.push("/shop");
                            break;
                        case 2:
                            const msg = `${process.env.NEXT_PUBLIC_NEXTAUTH_URL+router.asPath}`;
                            window.open(`https://m.me/gulongphilippines?ref=102452721335079&text=${msg}`, "_blank", "noopener");
                            break;
                        case 3:
                            window.open("tel:+639278691128", "_blank");
                            break;
                        default:
                            break;
                    }
                }}
            >
                <BottomNavigationAction label="Home" icon={<HomeOutlinedIcon />} />
                <BottomNavigationAction className="whitespace-nowrap" label="Shop Tires" icon={<ShoppingCartOutlinedIcon />} />
                <BottomNavigationAction label="Chat" icon={
                    <svg className="mx-auto" xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.36 2 2 6.13 2 11.7c0 2.91 1.19 5.44 3.14 7.17c.16.13.26.35.27.57l.05 1.78c.04.57.61.94 1.13.71l1.98-.87c.17-.06.36-.09.53-.06c.9.27 1.9.4 2.9.4c5.64 0 10-4.13 10-9.7C22 6.13 17.64 2 12 2m6 7.46l-2.93 4.67c-.47.73-1.47.92-2.17.37l-2.34-1.73a.6.6 0 0 0-.72 0l-3.16 2.4c-.42.33-.97-.17-.68-.63l2.93-4.67c.47-.73 1.47-.92 2.17-.4l2.34 1.76a.6.6 0 0 0 .72 0l3.16-2.4c.42-.33.97.17.68.63Z"/></svg>
                } />
                <BottomNavigationAction label="Call" icon={<PhoneEnabledOutlinedIcon />} />
            </BottomNavigation>
        </Box>
    );
}
