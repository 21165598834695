import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { AppBar, Badge, Dialog, FormGroup, IconButton, List, Slide, Toolbar, Typography } from "@mui/material";
import Link from 'next/link';
import Image from 'next/image';
import React, { createRef, useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

const Transitions = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function MobileNav({ mobileNav, handleCloseMobileNav, mobileNavigation, carType, tiresBrand, getItemsCount}){
    const router = useRouter();
    const navRef = useRef(mobileNavigation.map(() => createRef()));
    const catRef = useRef();
    const tireCatRef = useRef();
    const btnCart = useRef();
    useEffect(() =>{
        btnCart.current = document.getElementsByClassName("btnCart")[0];
    },[]);
    return(
        <Dialog
            fullScreen
            className="left-0 w-3/4"
            open={mobileNav}
            onClose={handleCloseMobileNav}
            TransitionComponent={Transitions}
        >
            <AppBar sx={{ position: 'relative' }} className="bg-white">
                <Toolbar className="bg-white">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseMobileNav}
                        aria-label="close"
                        className="btnFilter absolute-imp right-1 text-red-600 hover:bg-red-600 hover:text-white hover:scale-95 duration-200 ease-linear transition"
                    >
                        <CloseIcon className="" />
                    </IconButton>
                    <Typography sx={{ flex: 1 }} variant="h6" component="div" className="gulong-font">
                        <div className="relative mx-auto block w-40 h-16">
                            <Image
                                src="/assets/images/gulong_new_logo_low_res.webp"
                                alt="GulongPH logo"
                                fill
                                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                            />
                        </div>
                    </Typography>
                </Toolbar>
            </AppBar>
            <FormGroup>
                <List>
                {mobileNavigation.map((item, index) => {
                    if(item.name == "Promos"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none">
                                <Link href="/promos" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`${("/about-us" === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  font-bold lg:text-xl md:text-base gulong-font`}>
                                        Promo
                                    </span>
                                </Link>
                            </div>
                        )
                    }
                    else if(item.name == "Shop"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none">
                                <button
                                    onClick={
                                        () => {
                                            let disp = navRef.current[index].current.querySelector("ul.product-ul.hidden");
                                            let chevRight = navRef.current[index].current.querySelector(".prod-right");

                                            if(disp){
                                                navRef.current[index].current.querySelector("ul.product-ul").classList.remove('hidden');
                                                chevRight.style.cssText = 'transform: rotate(180deg)';
                                            }
                                            else{
                                                navRef.current[index].current.querySelector("ul.product-ul").classList.add('hidden');
                                                chevRight.style.cssText = 'transform: rotate(0)';
                                            }
                                        }
                                    }
                                    className="flex flex-row items-center  w-full whitespace-nowrap">
                                    <span className={`${("/shop" === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  font-bold nav-font-weight lg:text-xl md:text-base gulong-font`}>
                                        Shop
                                    </span>
                                    <ExpandLessIcon
                                        className="prod-right w-5 h-5 lg:ml-2 ml-1 !transition-all rotate-180"
                                        aria-hidden="true"
                                        />
                                </button>
                                <ul className="product-ul ml-4 relative top-0 left-0 transition bg-white mt-2">
                                    <li>
                                        <Link href="/shop" onClick={() => navRef.current[index].current.querySelector("ul").classList.add('hidden') } className="flex flex-row py-2 items-center w-full whitespace-nowrap focus:outline-none font-medium">
                                            <span className={`${("/products" === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  nav-font-weight lg:text-xl md:text-base gulong-font`}>
                                                All Products
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <div ref={catRef} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white py-2 cursor-pointer select-none">
                                            <button
                                                onClick={
                                                    () => {
                                                        let disp = catRef.current.querySelector("ul.cat-ul.hidden");
                                                        let chevRight = navRef.current[index].current.querySelector(".cat-right");

                                                        if(disp){
                                                            catRef.current.querySelector("ul.cat-ul").classList.remove('hidden');
                                                            chevRight.style.cssText = 'transform: rotate(180deg)';
                                                        }
                                                        else{
                                                            catRef.current.querySelector("ul.cat-ul").classList.add('hidden');
                                                            chevRight.style.cssText = 'transform: rotate(0)';
                                                        }
                                                    }
                                                }
                                                className="flex flex-row items-center  w-full whitespace-nowrap">
                                                <span className={`${("/categories" === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  nav-font-weight lg:text-xl md:text-base gulong-font font-medium`}>
                                                    Car Type
                                                </span>
                                                <ExpandLessIcon
                                                    className="cat-right w-5 h-5 lg:ml-2 ml-1 !transition-all"
                                                    aria-hidden="true"
                                                    />
                                            </button>
                                            <ul className="cat-ul hidden ml-4 relative top-0 left-0 transition bg-white mt-2">
                                                {carType.map((cat, ix) => {
                                                    return (
                                                        <li key={cat.id}>
                                                            <Link href={"/shop?c="+cat.category} className="flex flex-row items-center w-full py-1 whitespace-nowrap focus:outline-none">
                                                                <span className="nav-font-weight lg:text-xl md:text-base gulong-font">
                                                                    {cat.category}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div ref={tireCatRef} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white py-2 cursor-pointer select-none">
                                            <button
                                                onClick={
                                                    () => {
                                                        let disp = tireCatRef.current.querySelector("ul.tc-ul.hidden");
                                                        let chevRight = navRef.current[index].current.querySelector(".tc-right");

                                                        if(disp){
                                                            tireCatRef.current.querySelector("ul.tc-ul").classList.remove('hidden');
                                                            chevRight.style.cssText = 'transform: rotate(180deg)';
                                                        }
                                                        else{
                                                            tireCatRef.current.querySelector("ul.tc-ul").classList.add('hidden');
                                                            chevRight.style.cssText = 'transform: rotate(0)';
                                                        }
                                                    }
                                                }
                                                className="flex flex-row items-center  w-full whitespace-nowrap">
                                                <span className={`${("/brands" === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  nav-font-weight lg:text-xl md:text-base gulong-font font-medium`}>
                                                    Price Category
                                                </span>
                                                <ExpandLessIcon
                                                    className="tc-right w-5 h-5 lg:ml-2 ml-1 !transition-all"
                                                    aria-hidden="true"
                                                    />
                                            </button>
                                            <ul className="tc-ul hidden ml-4 relative top-0 left-0 transition bg-white mt-2 ">
                                                {tiresBrand.map((brand, x) => {
                                                    return (
                                                        <li key={brand.id}>
                                                            <Link href={"/shop?tc="+brand.slug} className="flex flex-row py-2 items-center hover:text-black w-full whitespace-nowrap focus:outline-none text-cente">
                                                                <span className={`${("/brand/"+brand.name === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  nav-font-weight lg:text-xl md:text-base text-center gulong-font`}>
                                                                    {brand.name}
                                                                </span>
                                                            </Link>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )
                    }
                    else if(item.name == 'Brands'){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none">
                                <Link href={item.href} className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`${(item.href === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  font-bold lg:text-xl md:text-base gulong-font`}>
                                        {item.name}
                                    </span>
                                </Link>
                            </div>
                        )
                    }
                    else if(item.name == "About Us"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none">
                                <Link href="/about-us" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`${("/about-us" === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  font-bold lg:text-xl md:text-base gulong-font`}>
                                        About Us
                                    </span>
                                </Link>
                            </div>
                        )
                    }
                    else if(item.name == "FAQ"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none">
                                <Link href="/faq" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`${("/faq" === router.route) ? 'nav-font-color-hf' :'nav-font-color'}  font-bold lg:text-xl md:text-base gulong-font`}>
                                        FAQ
                                    </span>
                                </Link>
                            </div>
                        )
                    }
                    else if(item.name == "CART"){
                        return (
                            <div key={item.name}
                                onClick={() => {
                                    if(getItemsCount > 0){
                                        btnCart.current?.click();
                                    }
                                    else{
                                        router.push('/shop');
                                    }
                                }}
                                className="flex align-middle items-center min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none">
                                <span className={`leading-8 h-8 font-bold lg:text-xl md:text-base gulong-font pr-2`}>
                                    Cart
                                </span>
                                <button className="w-10 h-10 text-red-600 hover:text-blue-600 transition duration-500">
                                    <Badge badgeContent={getItemsCount} color="error">
                                        <ShoppingCartIcon fontSize={"medium"}/>
                                    </Badge>
                                </button>
                            </div>
                        )
                    }
                    else if(item.name == "HELP"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none ">
                                <hr className="p-2"/>
                                <a className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                        NEED HELP?
                                    </span>
                                </a>
                                <Link href="/quotation" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font`}>
                                        <ContentPasteIcon/> Request for a quote
                                    </span>
                                </Link>
                                <a target="_blank" rel="noopener noreferrer" href="mailto: hello@gulong.ph" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={` hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font`}>
                                        <EmailIcon/> hello@gulong.ph
                                    </span>
                                </a>
                                <a className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                        For Sales/Inquiries:
                                    </span>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="tel:+639278691128" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font`}>
                                        <LocalPhoneIcon/>+63 9278691128 (Rolyn)
                                    </span>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="tel:+639456875710" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font`}>
                                        <LocalPhoneIcon/>+63 9628462325 (Rema)
                                    </span>
                                </a>
                                <a className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                        For Concerns:
                                    </span>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="tel:+639628462325" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font`}>
                                        <LocalPhoneIcon/>+63 9456875710 (Patrick)
                                    </span>
                                </a>
                                <a className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                        <QueryBuilderIcon/>&nbsp;Office hrs:
                                    </span>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="tel:+639628462325" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font`}>
                                        Monday – Saturday <br/> 8 AM – 5 PM
                                    </span>
                                </a>
                            </div>
                        )
                    }
                    else if(item.name == "FOLLOW"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none ">
                                <hr className="p-2"/>
                                <a className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                        FOLLOW US
                                    </span>
                                </a>
                                <a href="https://www.facebook.com/gulongphilippines/" target="_blank" rel="noopener noreferrer" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font`}>
                                        <FacebookIcon/> Facebook
                                    </span>
                                </a>
                                <a href="https://www.instagram.com/gulong_ph/" target="_blank" rel="noopener noreferrer" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`hover:bg-red-400 hover:text-white py-1 px-4 block text-base whitespace-nowrap gulong-font`}>
                                        <InstagramIcon/> Instagram
                                    </span>
                                </a>
                                <a href="https://www.tiktok.com/@gulongph" target="_blank" rel="noopener noreferrer" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`flex hover:bg-red-400 hover:text-white py-1 px-4 text-base whitespace-nowrap gulong-font`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6 mr-1 fill-current" viewBox="0 0 16 16">
                                        <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
                                        </svg>&nbsp;Tiktok
                                    </span>
                                </a>
                            </div>
                        )
                    }
                    else if(item.name == "How to Buy"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none ">
                                <hr className="p-2"/>
                                <Link
                                    key={item.name}
                                    href="/how-to-buy" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                        How to Buy
                                    </span>
                                </Link>
                            </div>
                        )
                    }
                    else if(item.name == "Privacy Policy"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none ">
                                <Link
                                    key={item.name}
                                    href="/privacy-policy" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                        <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                            Privacy Policy
                                        </span>
                                </Link>
                            </div>
                        )
                    }
                    else if(item.name == "Terms and Condition"){
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none ">
                                <Link
                                    key={item.name}
                                    href="/terms-and-conditions" className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                        <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                            Terms and Condition
                                        </span>
                                </Link>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div key={item.name} ref={navRef.current[index]} className="relative min-h-0 w-full focus:outline-none overflow-hidden bg-white px-3 py-2 cursor-pointer select-none">
                                <Link href={item.href} className="flex flex-row items-center hover:text-black w-full whitespace-nowrap focus:outline-none">
                                    <span className={`font-bold lg:text-xl md:text-base gulong-font`}>
                                        {item.name}
                                    </span>
                                </Link>
                            </div>
                        )
                    }
                })}
                </List>
            </FormGroup>
        </Dialog>
    );
}
